exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-tag-js": () => import("./../../../src/pages/tag.js" /* webpackChunkName: "component---src-pages-tag-js" */),
  "component---src-templates-category-template-js": () => import("./../../../src/templates/categoryTemplate.js" /* webpackChunkName: "component---src-templates-category-template-js" */),
  "component---src-templates-contacts-js": () => import("./../../../src/templates/contacts.js" /* webpackChunkName: "component---src-templates-contacts-js" */),
  "component---src-templates-faq-js": () => import("./../../../src/templates/faq.js" /* webpackChunkName: "component---src-templates-faq-js" */),
  "component---src-templates-information-about-company-js": () => import("./../../../src/templates/informationAboutCompany.js" /* webpackChunkName: "component---src-templates-information-about-company-js" */),
  "component---src-templates-posts-page-js": () => import("./../../../src/templates/postsPage.js" /* webpackChunkName: "component---src-templates-posts-page-js" */),
  "component---src-templates-reviews-js": () => import("./../../../src/templates/reviews.js" /* webpackChunkName: "component---src-templates-reviews-js" */),
  "component---src-templates-servicestemplatepage-js": () => import("./../../../src/templates/servicestemplatepage.js" /* webpackChunkName: "component---src-templates-servicestemplatepage-js" */),
  "component---src-templates-tags-page-js": () => import("./../../../src/templates/tagsPage.js" /* webpackChunkName: "component---src-templates-tags-page-js" */),
  "component---src-templates-template-blog-js": () => import("./../../../src/templates/templateBlog.js" /* webpackChunkName: "component---src-templates-template-blog-js" */)
}

